import React from 'react'

import logo from '../../images/logo.png'

import styles from './Footer.module.css'

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer_item}>
                <h3 className={styles.title} alt="title='Çiftçioğşu Nakliyat Adres Bilgileri'" title='Çiftçioğşu Nakliyat Adres Bilgileri'>Adres Bilgileri</h3>
                <p className={styles.adress} alt="title='Çiftçioğşu Nakliyat Adres Bilgileri'" title='Çiftçioğşu Nakliyat Adres Bilgileri'>Haliliye mahallesi 46. Sokak no 3 Siverek Şanlıurfa, 63600</p>
                <p className={styles.adress} alt="title='Çiftçioğşu Nakliyat Adres Bilgileri'" title='Çiftçioğşu Nakliyat Adres Bilgileri'>Siverek/Şanlıurfa, Türkiye</p>
                <p className={styles.adress} alt="title='Çiftçioğşu Nakliyat Adres Bilgileri'" title='Çiftçioğşu Nakliyat Adres Bilgileri'>+90 546 828 63 00</p>
                <p className={styles.adress} alt="title='Çiftçioğşu Nakliyat Adres Bilgileri'" title='Çiftçioğşu Nakliyat Adres Bilgileri +'>Siverek Çiftçioğlu Nakliyat Şirketi</p>
            </div>
            <div className={styles.footer_item}>
                <img src={logo} width="100%" alt="Şanlıurfa/Siverek Çifçioğlu Asansörlü Nakliyat Şirketi Logo" />
            </div>
            <p className={styles.copyright}>&copy; Coder By Murat Akyol</p>
        </footer>
    )
}

export default Footer
