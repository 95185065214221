import React from 'react'

import styles from './GallerySection.module.css'

const GallerySection = ({ photos }) => {
    return (
        <section className={styles.gallery}>
            <header className={styles["gallery-header"]}>
                <h1 className={styles["gallery-title"]}>Nakliyat Hizmetlerimiz</h1>
            </header>
            <section>
                <div className={styles.photos}>
                    {photos && photos.map(photo => (
                        <div className={styles.item}>
                            <img src={photo} alt="siverek ÇiftçiOğlu nakliyat şirketi" title="siverek Çiftçioğlu nakliyat şirketi" className={styles.photo} />
                        </div>
                    ))}
                </div>
            </section>
        </section>
    )
}

export default GallerySection
