import React from 'react'

import styles from './Slide.module.css'

const Slide = (props) => {
    return (
        <div className={styles.slide}>
            <img src={props.src} alt="Vercel Logo" className={styles.slideImg} />
        </div>
    )
}

export default Slide
