import React, { useRef } from 'react'

import { Link } from 'react-router-dom'

import logo from '../../images/logo.png'
import styles from './Navbar.module.css'
import "./Navbar.css"

const Navbar = () => {
    const navLinks = useRef(null)
    const toggleHandler = (e) => {
        navLinks.current.classList.toggle("active")
    }
    return (
        <nav id='navbar' className={styles.navbar}>
            <div className={styles.navLogo}>
                <Link to="/"><img src={logo} width="50" className={styles.logo} alt='Çiftçi Oğlu Nakliyat Logo' title='Çiftçi Oğlu Nakliyat Logo' /></Link>
            </div>
            <div className={styles.navBtn}><i className='fas fa-bars' onClick={toggleHandler}></i></div>
            <div className={styles.navLinks} ref={navLinks}>
                <Link to="/" className={styles.navLink}>Anasayfa</Link>
                <Link to="/about" className={styles.navLink}>Hakkımızda</Link>
                <a target='_blank' className={styles.navStaticLink} href="https://maps.google.com/maps?um=1&fb=1&gl=tr&sa=X&geocode=KRnBC1vFgXVAMQUNZHc2zHs9&daddr=Ayvanat,+Bed%C3%BCzaman+Said-i+Nursi+Bulvari+No:26+CA,+63600+Siverek/%C5%9Eanl%C4%B1urfa&ved=2ahUKEwjKn4znjMf-AhUBBzoCHa4sASkQ48ADegQIABAt">Yol Tarifi Al</a>
            </div>
        </nav>
    )
}

export default Navbar
