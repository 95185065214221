import React from 'react'

import Slider from '../slider/Slider'

import styles from './modules/Header.module.css'

const Header = () => {
    return (
        <header className={styles.header}>
            <Slider />
        </header>
    )
}

export default Header
