import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../components/navbar/Navbar'
import ContactSection from '../components/contact-section/ContactSection'
import Footer from '../components/footer/Footer'
import WpIcon from '../components/wp-icon/WpIcon'

const Layout = () => {
    return (
        <div>
            <ContactSection />
            <Navbar />
            <Outlet />
            <WpIcon />
            <Footer />
        </div>
    )
}

export default Layout
