import React from 'react'

import About from '../components/about/About'
import { Helmet } from 'react-helmet'

const AboutPage = () => {
    return (
        <div>
            <Helmet>
                <title>Siverek Nakliyat - Hakkımızda</title>
                <meta name="description" content="Şanlıurfa/Siverek Çiftçioğlu Nakliyat her an hizmetinizde. Asansörlü Nakliyat hizmetimiz ile hızlı teslimat sunmaktayız." />
                <meta name="keywords" content="Şanlıurfa,siverek,nakliyat,siverek nakliyat,siverek taşıma,taşımacılık,nakliyat asansörü,asansörlü,asansörlü nakliyat,siverek asansörlü nakliyat,çiftçioğlu,ciftcioglu nakliyat hizmetleri,şanlıurfa/siverek,gaziantep,haaalanı,diyarbakır,kamyonet,araçlarımız,arabam,nakliyatçım,siverekçiftçioğlunakliya" />
                <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=7" />
            </Helmet>
            <About />
        </div>
    )
}

export default AboutPage
