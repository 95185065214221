import React, { useEffect } from "react";

import Header from "../components/header/Header";
import WorkSection from "../components/work-section/WorkSection";
import ContactSection from "../components/contact-section/ContactSection";
import GallerySection from "../components/gallery-section/GallerySection";

import galleryImages from "../components/gallery-section/galleryImages";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Siverek Evden Eve Nakliyat - Çiftçioğlu Nakliyat</title>
        <meta name="title" content="Siverek Çiftçioğlu Naklşiyat" />
        <meta
          name="description"
          content="Şanlıurfa/Siverek Çiftçioğlu Nakliyat her an hizmetinizde. "
        />
        <meta
          name="keywords"
          content="Şanlıurfa,siverek,nakliyat,siverek nakliyat,siverek taşıma,taşımacılık,nakliyat asansörü,asansörlü,asansörlü nakliyat,siverek asansörlü nakliyat,çiftçioğlu,ciftcioglu nakliyat hizmetleri,şanlıurfa/siverek,gaziantep,haaalanı,diyarbakır,kamyonet,araçlarımız,arabam,nakliyatçım,siverekçiftçioğlunakliya"
        />
        <meta http-equiv="X-UA-Compatible" content="IE=7" />
      </Helmet>
      <Header />
      <WorkSection />
      <GallerySection photos={galleryImages} />
    </div>
  );
};

export default Home;
