import image from "../../images/siverek-ciftcioglu-nakliyat-fotograf.jpg";
import image1 from "../../images/çiftçioğlu-nakliyat.jpg";
import image2 from "../../images/evden-eve-nakliyeci.jpg";
import image3 from "../../images/ciftcioglu-nakliye.jpg";
import image4 from "../../images/4.jpg";
import image5 from "../../images/ciftcioglu-nakliye-evden-eve.jpg";
import image6 from "../../images/1.jpg";
import image7 from "../../images/2.jpg";
import image8 from "../../images/3.jpg";


import image9 from "../../images/5.jpg";
import image10 from "../../images/6.jpg";
import image11 from "../../images/7.jpg";
import image12 from "../../images/8.jpg";
import image13 from "../../images/9.jpg";
import image14 from "../../images/10.jpg";

export default [
  image,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14

];
