import React from 'react'

import styles from './WorkSection.module.css'

const WorkSection = () => {
    return (
        <div className={styles.WorkSection}>
            <div className={styles.item}>
                <h3 className={styles.item_title}>Hızlı Teslimat</h3>
                <i className="fas fa-star fa-6x"></i>
            </div>

            <div className={styles.item}>
                <h3 className={styles.item_title}>Ekonomik Fiyat</h3>
                <i className="fas fa-money-check-alt fa-6x"></i>
            </div>

            <div className={styles.item}>
                <h3 className={styles.item_title}>Kaliteli Taşıma</h3>
                <i className="fas fa-boxes fa-6x"></i>
            </div>
        </div>
    )
}

export default WorkSection
