import React from 'react'

import styles from './WpIcon.module.css'
import WpI from './WhatsApp.svg'
const WpIcon = () => {
    return (
        <a className={styles["whatsapp-icon"]} href="https://api.whatsapp.com/send/?phone=%2B905468286300&text=Merhabalar,%20kolay%20gelsin." target='_blank'>
            <img src={WpI} alt='Çiftçioğlu nakliyat Whatsapp' title='Bizimle İletişime Geçin' />
        </a>
    )
}

export default WpIcon
