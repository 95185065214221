import React, { useEffect, useRef } from 'react'

import styles from './About.module.css'
import './About.css'


const About = () => {
    const toggleHandler = (e) => {
        e.target.parentElement.children[0].classList.toggle("active")
        e.target.parentElement.children[1].classList.toggle("active")
    }
    return (
        <div className={styles.about}>
            <div className={styles.about_menu}>
                <div className={styles.about_toggle}>
                    <h3 onClick={toggleHandler} className={styles.toggle_title}>Hangi bölgelere hizmet veriyorsunuz ? <i class="fa-solid fa-circle-chevron-down"></i></h3>
                    <p className={styles.toggle_description} >Şanlıurfa Çevresindeki il ve ilçelere nakliyat hizmeti vermekteyiz.</p>
                </div>
                <div className={styles.about_toggle}>
                    <h3 onClick={toggleHandler} className={styles.toggle_title}>Asansörlü Nakliyat Hizmeti Veriyor musunuz ? <i class="fa-solid fa-circle-chevron-down"></i></h3>
                    <p className={styles.toggle_description} >Asansörlü Nakliyat hizmetimiz ile kolayca nakliyat işlemlerinizi gerçekleştiriyoruz.</p>
                </div>
            </div>
        </div>
    )
}

export default About
