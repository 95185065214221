import React from 'react'


import styles from './CotactSection.module.css'
const ContactSection = () => {
  


    return (
        <section className={styles.contact}>
            <div className={styles["contact-detail"]}>
                <div className={styles.media}>
                    <a href="https://instagram.com/siverekciftcioglu" target='_blank' ><i className="fab fa-instagram"></i></a>
                    <a href='https://tr-tr.facebook.com/ilyas.boztepe.9' target='_blank'><i className="fab fa-facebook"></i></a>
                    <a href='https://api.whatsapp.com/send/?phone=%2B905468286300&text=Merhabalar,%20kolay%20gelsin.' target='_blank'><i className="fab fa-whatsapp"></i></a>
                </div>
                <div className={styles.company}>
                    <span className={styles.phone}><i className="fas fa-phone"></i> 0546 828 63 00</span>
                    <a id='phoneMe' href='tel:+905468286300' className={styles.phoneBtn}><i className="fas fa-phone"></i> Hemen Ara</a>
                </div>
            </div>
        </section>
    )
}

export default ContactSection
