import React, { useEffect, useRef, useState } from "react";

import Slide from "./modules/slide/Slide";

import styles from "./Slider.module.css";

import img1 from "../../images/siverek-ciftcioglu-nakliyat-fotograf.jpg";
import img2 from "../../images/evden-eve-nakliye-hizmetleri.jpg";
import img3 from "../../images/çiftçioğlu-nakliyat.jpg";
import img4 from "../../images/1.jpg";
import img5 from "../../images/2.jpg";
import img6 from "../../images/3.jpg";
import img7 from "../../images/4.jpg";

import img8 from "../../images/5.jpg";
import img9 from "../../images/6.jpg";
import img10 from "../../images/7.jpg";
import img11 from "../../images/8.jpg";
import img12 from "../../images/9.jpg";
import img13 from "../../images/10.jpg";

const Slider = (props) => {
  const [children, setChildren] = useState();
  const [isActive, setIsActive] = useState(0);
  const slidesRef = useRef(null);

  useEffect(() => {
    setChildren([...slidesRef.current.children]);
  }, [slidesRef]);

  const nextSlider = async () => {
    const count = children.length;
    if (isActive >= count - 1) {
      setIsActive(0);
      slidesRef.current.style.transform = `translateX(-0%)`;
    } else {
      setIsActive(isActive + 1);
      slidesRef.current.style.transform = `translateX(-${
        (isActive + 1) * 100
      }%)`;
    }
  };

  const prevSlider = () => {
    const count = children.length;
    if (isActive <= 0) {
      slidesRef.current.style.transform = `translateX(-${(count - 1) * 100}%)`;
      setIsActive(count - 1);
    } else {
      setIsActive(isActive - 1);
      slidesRef.current.style.transform = `translateX(-${
        (isActive - 1) * 100
      }%)`;
    }
  };

  const setSliderIndex = (index) => {
    slidesRef.current.style.transform = `translateX(-${index * 100}%)`;
  };

  return (
    <div className={styles.slider}>
      <div ref={slidesRef} id="slides" key={3} className={styles.slides}>
        <Slide src={img1} alt="Çiftçi oğlu nakliyat Siverek" />
        <Slide src={img2} alt="ev taşıma" />
        <Slide src={img3} alt="asansörlü evden eve" />
        <Slide src={img4} alt="siverek naliyeciler" />
        <Slide src={img5} alt="siverek nakliyecim" />
        <Slide src={img6} alt="Evden eve taşıma" />
        <Slide src={img7} alt="Çiftçi oğlu nakliyat" />
        <Slide src={img8} alt="siverek saraykent nakliyat" />
        <Slide src={img9} alt="saraykent nakliyat" />
        <Slide src={img10} alt="Çiftçi Evden eve nakliyat" />
        <Slide src={img11} alt="siverek nakliyat" />
        <Slide src={img12} alt="siverek ofis mahallesi" />
        <Slide src={img13} alt="siverek ofis nakliyat" />
      </div>
      <div className={styles.sliderButtons}>
        <button className={styles.prev} onClick={prevSlider}>
          <i className="fas fa-circle-left"></i>
        </button>
        <button className={styles.next} onClick={nextSlider}>
          <i className="fas fa-circle-right"></i>
        </button>
      </div>

      <div className={styles.sliderIndex}>
        {children &&
          children.map((d, i) => (
            <span
              onClick={() => {
                setIsActive(i);
                setSliderIndex(i);
              }}
              className={isActive == i ? styles.active : styles.slideIndex}
              key={i + 1}
            ></span>
          ))}
      </div>
    </div>
  );
};

export default Slider;
